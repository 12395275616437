import './App.css';
import {
  BrowserRouter as Router,
  Link,
  Route, Routes
} from "react-router-dom";
import About from "./components/About";
import Home from "./components/Home";

function App() {
  return <Router>
    <div className="min-h-full">
      <header className="w-full bg-[#353b48] text-white sticky top-0">
        <section className="text-center pt-2 pb-2 md:pb-0">
          <h1 className="text-xl">ReyZeal</h1>
          <h2 className="text-xs text-gray-500">Rizal Ardhi Rahmadani</h2>
        </section>

        <nav className="hidden md:block">
          <ul className="flex justify-center flex-row gap-3">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </nav>
        <nav className="fixed md:hidden bottom-0 w-full bg-[#353b48]">
          <ul className="flex justify-center flex-row gap-3">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </nav>
      </header>

      <div className="min-h-full">
        <Routes>
          <Route path="/about" element={<About/>}/>
          <Route path="/" element={<Home/>}/>
        </Routes>
      </div>

      <footer className="w-full bg-[#353b48] text-center text-gray-500">
        <ul>
          <li>
            <a href="https://twitter.com/reyzeal"></a>
          </li>
          <li>
            <a href="https://instagram.com/reyzeal"></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/reyzeal/"></a>
          </li>
        </ul>
        <p>&copy; {new Date().getFullYear()}</p>
      </footer>
    </div>
  </Router>
}

export default App;
